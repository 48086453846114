import React, { useState } from 'react';
import {
  Stack,
  Typography,
  Box,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ListItem,
  } from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import timberHouse from "../assets1/timberuse.png";
import groupImg from "../assets1/groupImg.jpg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Carousel from "./Carousel";

const OPTIONS = {}
const SLIDE_COUNT = 5
const SLIDES = Array.from(Array(SLIDE_COUNT).keys())


function Hero(probs) {
  const { language } = probs;
  // Accordion Styling
  
  // Translation Text
  const heroInfoDe = {
    contactButton: "KONTAKT",
    contactSpace: "Platzhalter...",
    infoText: "Das Forschungsvorhaben wird durch ein interdisziplinäres Konsortium aus Forschungs- und Industriepartnern bearbeitet. Beginnend mit Untersuchungen auf Gebäudeebene, wird die Nutzungsflexibilität und Anpassungsfähigkeit von kreislaufgerechten Holztafelbauweisen bewertet. Auf Bauteilebene werden daran anbindend, Bauteilanschlüsse mit dem Ziel der Demontierbarkeit und Trennbarkeit der Bauteilschichten bis auf die Materialebene betrachtet. Auf Materialebene steht insbesondere die Kaskadennutzung von Vollholz im Fokus der qualitativen und quantitativen Bewertungen. Die Charakterisierung der Verwertungsoptionen des Gebrauchtholzes auf Materialebene trägt dazu bei, dass das Gebrauchtholz nach dem Rückbau der kreislaufgerechten Konstruktion auch tatsächlich einer hochwertigen Kaskadennutzung zugeführt werden kann. Eine über alle Ebenen übergreifende, gesamtheitliche Betrachtung gestattet die Einbindung digitaler Technologien zur Entwicklung eines digitalen Materialpasses, mit dem Ziel der Dokumentation und Nachverfolgung von Bauteilen, Verbindungen und Rückbauoptionen. Auf Grundlage von Lebenszyklusanalysen erfolgt neben der technischen und materialwissenschaftlichen Bewertung die Evaluierung der ökologischen und ökonomischen Effekte. Die Untersuchung von innovativen Wirtschaftskonzepten aus der Kreislaufwirtschaft, wie Leasing- oder Sharing-Modelle runden das Verbundprojekt, mit einem Blick in die Zukunft des modernen Holzbaus, ab.",
  };
  const heroInfoEn = {
    contactButton: "CONTACT",
    contactSpace: "Space...",
    infoText: "The research project is being carried out by an interdisciplinary consortium of research and industry partners. Starting with investigations at the building level, the flexibility of use and adaptability of circular timber panel construction methods will be evaluated. At the component level, component connections are examined with the aim of dismantling and separability of the component layers down to the material level. On the material level, the cascade utilization of solid wood is the focus of the qualitative and quantitative evaluations. The characterization of the utilization options of the used wood on the material level contributes to the fact that the used wood can actually be supplied to a high-quality cascade utilization after the deconstruction of the cycle-appropriate construction. A holistic view across all levels allows the integration of digital technologies for the development of a digital material passport, with the aim of documenting and tracking components, connections and deconstruction options. On the basis of life cycle analyses, the evaluation of the ecological and economic effects is carried out in addition to the technical and material-scientific assessment. The investigation of innovative economic concepts from the circular economy, such as leasing or sharing models, round off the joint project, with a look into the future of modern timber construction.",
  };
  const heroInfo = language === "de" ? heroInfoDe : heroInfoEn;

  // Titel und Text
  const LaufzeitDe = {
    maintitel1: "Entwicklung und Bewertung",
    maintitel2: "von kreislaufgerechten",
    maintitel3: "Holztafelbaukonstruktionen",
    title: "Laufzeit: Januar 2023 - Dezember 2025",
  };
  const LaufzeitEn = {
    maintitel1: "Development and evaluation",
    maintitel2: "of circular timber",
    maintitel3: "panel construction",
    title: "Duration: January 2023 - December 2025",
  };
  const Laufzeit = language === "de" ? LaufzeitDe : LaufzeitEn;

  // Titel und Text
  const ForschungsvorhabenDe = {
    title: "Forschungsvorhaben",
  };
  const ResearchProjectEn = {
    title: "Research Project",
  };
  const Forschungsvorhaben = language === "de" ? ForschungsvorhabenDe : ResearchProjectEn;

      // Titel und Text
      const AktuellesDe = {
        title: "Aktuelles",
        subtitle: "Eine Übersicht über unsere aktuelle Veranstaltungen.",
        text: "Am 01.02.2023 fand an der Holzforschung München das Kick-off Meeting des am 01.01.2023 gestarteten Forschungsvorhabens „TU&M – Timber Use and Maintain“ statt. In dem Vorhaben sollen kreislaufgerechte Holztafelelemente unter Berücksichtigung einer technischen, ökologischen und ökonomischen Realisierbarkeit entwickelt werden. Auf Gebäudeebene werden die Potentiale der kreislaufgerechten Konstruktion als Beitrag zum adaptiven und nutzungsflexiblen Holzbau ermittelt. Die konstruktive Entwicklung der Holztafelelemente erfolgt auf zwei Ebenen: Die demontagegerechte Entwicklung der Bauteile soll einen einfachen Rückbau und die Wiederverwendung der Elemente ermöglichen. Die Trennbarkeit der Bauteile garantiert eine sortenreine Trennung der im Tafelelement eingesetzten (holzbasierten) Materialien. Auf Materialebene wird das Nachnutzungspotential der eingesetzten Materialien im Sinne der Kaskadennutzung und ihrem erneuten Einsatz in den Holztafelelementen untersucht. Begleitende Untersuchungen zur ökologischen und ökonomischen Performance sowie die Entwicklung einer digitalen Dokumentation runden das Forschungsvorhaben ab. Das Vorhaben ist aus dem Netzwerk TUM.wood (holz.tum.de) der Technischen Universität München hervorgegangen und umfasst die Professuren Holzwissenschaft, Holzbau und Baukonstruktion, Architektur und Holzbau, Architekturinformatik und Circular Economy. Als Industriepartner sind die Firmen Haas Fertigbau und Brüninghoff Holz Teil des Konsortiums. Das Vorhaben wird vom BMEL für 36 Monate unter den Kennzeichen 2221HVO37A-E gefördert.",
      };
      const AktuellesEn = {
        title: "News",
        subtitle: "An overview of our current events.",
        text: "On February 1st, 2023, the Kick-off Meeting for the research project “Timber Use and Maintain – TU&M”, started on January 1st the same year, was held at the Wood Research Institute in Munich. The project aims to develop circular timber frame elements considering their technical, environmental and economic feasibility. On building level, the potentials of a circular design as a contribution to adaptive and flexible timber buildings will be analyzed. The technical development of the timber frame elements will be conducted on two levels: The elements will be designed to enable an easy disassembly and their re-use as well as to allow the separation of the (wood-based) materials at the end-of-life. On material level, the cascading potential of the materials and their potential (re-)use in the timber frame elements will be determined. The technical development is accompanied by studies on the environmental and economic performance of the elements as well as the development of digital documentation solutions. The project emerged from the TUM.wood network (holz.tum.de) at the Technical University of Munich and includes the professorships on Wood Science, Timber Structures and Building Construction, Architecture and Timber Construction, Architectural Informatics and Circular Economy. The industry partners are Haas Fertigbau and Brüninghoff Holz. The project is funded for 36 months by the German BMEL (funding codes 2221HVO37A-E).",
      };
      const Aktuelles = language === "de" ? AktuellesDe : AktuellesEn;

  return (
    <Box>
      <Stack
        className="hero"
        direction={{ sm: "column", md: "row" }}
        px={{ xs: "2%", sm: "7%" }}
      //pt="20px"
      //pb="50px"
      >
        <Box width={{ xs: "100%", sm: "100%", md: "100%", xl: "50%" }} pt={{ xs: "15px", sm: "30px" }}>
          <br />
          <Typography
            className="tum"
            variant="h1"
            fontWeight="800"
            fontSize={{ xs: "30px", sm: "40px", md: "46px" }}
          >
            {Laufzeit.maintitel1}
            <br />{Laufzeit.maintitel2}
            <br />{Laufzeit.maintitel3}
          </Typography>
          <div id={Forschungsvorhaben.title}></div>
          <Box>
            <Typography
              className="tum"
              variant="h2"
              fontWeight="400"
              fontSize={{ xs: "18px", sm: "24px", md: "30px" }}
              mt={{ xs: "10px", sm: "15px", md: "20px" }}
            >
              {Laufzeit.title}
            </Typography>
            <Accordion sx={{ boxShadow: "none", margin: '0px', mt: { xs: "20px", sm: "30px", md: "40px" } }} >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon />
                }
                sx={{
                  padding: '0px',
                  margin: '0px',
                  '& .MuiSvgIcon-root': {
                    fontSize: '3rem',
                    color: '#000000',
                    padding: '0px',
                    margin: '0px',
                  },
                                    
                }}
              >
                <Typography
                  variant="h2"
                  fontWeight="800"
                  fontSize={{ xs: "28px", sm: "34px", md: "44px" }}
                  
                //mt={{ xs: "10px", sm: "15px", md: "20px" }}
                >
                  {Forschungsvorhaben.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                 <Typography variant="body1" fontWeight="500" >
                  {heroInfo.infoText}
                </Typography>
              </AccordionDetails>
            </Accordion>
            {/*
          </Box>
          <Box mt={{ xs: "20px", sm: "25px", md: "40px" }} >
            <Button
              href="mailto:florian.boehm@tum.de"
              //sx={{ mt: "60px" }}
              variant="contained"
              className="hero-button"
              endIcon={<ArrowRightAltIcon />}
            >
              {heroInfo.contactButton}
            </Button>
            {/* <HeroAvatars />
        <Typography mt="10px" variant="body1" fontWeight="500">
          {heroInfo.contactSpace}
        </Typography>
         */}
          </Box>
        </Box>
{/* 
        <div className="sandbox">
        <section className="sandbox__carousel">
        	<Carousel slides={SLIDES} options={OPTIONS} />
        </section>
        </div>
        

        <Box flex={0.95} pt={{ sm: "100px", md: "100px" }}>
          <img className="hero-image" src={timberHouse} alt="graphic"/>
        </Box>
  */}
      </Stack >
   {/* 
      <div id={Aktuelles.title}></div>
      <Box px={{ xs: "2%", sm: "7%" }} pt="35px" pb="50px">
        <Box pt={{ xs: "15px", sm: "30px" }} >
          <Typography  
            variant="h2"
            fontWeight="800"
            fontSize={{ xs: "28px", sm: "34px", md: "44px" }}
            mt={{ xs: "10px", sm: "15px", md: "20px" }}
            textAlign="left"
            mb={{ xs: "15px", sm: "25px" }}
          >
            <br />{Aktuelles.title}
          </Typography>
          <Typography
          variant="body1"
          fontWeight="500"
        >
          {Aktuelles.subtitle}
        </Typography>
        </Box>
      </Box>
*/}
         {/* 
      <Box px={{ xs: "2%", sm: "7%" }} pb="50px">
      <Box sx={{ display: 'flex'}} flexDirection={{ xs: "column", sm: "column", md: "column", xl: "row" }}>         
          <Box width={{ xs: "100%", sm: "100%", md: "100%", xl: "50%" }}>
          <Typography
            variant="body1"
            fontWeight="500"
          >
          {Aktuelles.text}
          </Typography>
          </Box>       
          <Box mt={{ xs: "35px", sm: "35px", md: "35px", xl: "0px" }} ml={{ xs: "12.5%", sm: "12.5%", md: "12.5%", xl: "0%" }} width={{ xs: "75%", sm: "75%", md: "75%", xl: "50%" }}>
          <img className="hero-image" src={groupImg} alt="graphic" style={{flex:1}}/>
          </Box>          
      </Box>
    </Box>     */}
    </Box>
 
  );
}
export default Hero;

import React, { useState } from "react";
import { createTheme, ThemeProvider, Divider } from "@mui/material";
import "./App.css";
import Hero from "./components/Hero";
import Partner from "./components/Partner";
import Förderung from "./components/Förderung";
import Carousel from "./components/Carousel";
import Veröffentlichungen from "./components/Veröffentlichungen";
import Footer from "./components/Footer";
import Contact from "./components/Contact";
import Copyright from "./components/Copyright";
import Navbar from "./components/Navbar";
import Accordion from "./components/Accordion";
import imageByIndex from './components/imageByIndex'

import './css/sandbox.css'
import './css/embla.css'

const OPTIONS = {}
const SLIDE_COUNT = 3
const SLIDES = Array.from(Array(SLIDE_COUNT).keys())
const theme = createTheme({
  typography: {
    fontFamily: [
      "Manrope",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

function App() {
  const [language, setLanguage] = useState("de");
  

  const handleLanguageChange = () => {
    if (language === "en") {
      setLanguage("de");
    } else {
      setLanguage("en");
    }
  };

  
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Navbar language={language} handleLanguageChange={handleLanguageChange} />
        
        <Hero language={language}/>
        
        {/*  <div className="sandbox">
        <section className="sandbox__carousel">
        	<Carousel language={language} slides={SLIDES} options={OPTIONS} />
        </section>
        </div>
        */}
        <Accordion language={language}/>
        <Veröffentlichungen language={language}/>
        <Divider orientation="horizontal" />
        <Partner language={language}/>
        <Divider orientation="horizontal" />
        <Förderung language={language}/>
        <Contact language={language}/>
        <Divider className="footer-divider" orientation="horizontal" />    
        <Divider className="footer-divider" orientation="horizontal" />
        <Copyright language={language}/>
      </ThemeProvider>
    </div>
  );
}

export default App;

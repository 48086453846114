import React from "react";
import {
  Box,
  Stack,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function Veröffentlichungen(probs) {
  const { language } = probs;

  const contentDe = {
    publicationsTitle: "Veröffentlichungen",
    publicationsText: "Hier finden Sie unsere Veröffentlichungen. ",
  };
  const contentEn = {
    publicationsTitle: "Publications",
    publicationsText: "Here you can find our publications."
  };
  const content = language === "de" ? contentDe : contentEn;


  const PublishDataDe = [
    {
      question: "2023",
      answer: "Veröffentlichungen vom Jahr 2023."
      },
    {
      question: "2024",
      answer:
      "Veröffentlichungen vom Jahr 2024."
      },
    {
      question: "2025",
      answer:
      "Veröffentlichungen vom Jahr 2025."
      },
  ];

  const PublishDataEn = [
    {
      question: "2023",
      answer: "2023 Publications.",
    },
    {
      question: "2024",
      answer: "2024 Publications.",
    },
    {
      question: "2025",
      answer: "2025 Publications.",
    },
  ];
  const PublishData = language === "de" ? PublishDataDe : PublishDataEn;

  return (
    <Stack
      //px={{ xs: "2%", sm: "7%" }}
      flexDirection={{ xs: "column", sm: "column", md: "column", xl: "row" }}
      //py={{ xs: "25px", sm: "40px", md: "50px" }}
      px={{ xs: "2%", sm: "7%" }}
      display='flex'
    >
      <Box flex="0.5" paddingRight={{ xs: "0%", sm: "3%", md: "6%" }} sx={{ order: 1 }}>
        <div id={content.publicationsTitle}></div><br /><br /><br />
        <Typography
          variant="h2"
          fontWeight="800"
          fontSize={{ xs: "28px", sm: "34px", md: "44px" }}
          mb={{ xs: "15px", sm: "25px" }}
        >
          {content.publicationsTitle} <br />
        </Typography>
        <Typography
          variant="body1"
          fontWeight="500"
        >
          {content.publicationsText}
        </Typography>
      </Box>

      <Box 
        flex="0.5" 
        //sx={{ order: 2, my: { xs: "0px", sm: "70px" }}}
        sx={{ order: 2, mt: { xs: 0, sm: 10 } }}
        mb={{ xs: "12px", sm: "18px", md: "25px" }}
      >
        {PublishData.map((data) => (
          <Accordion sx={{ boxShadow: "none", my: { xs: "10px", sm: "20px" } }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="body1" fontWeight="500">
                {data.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1">{data.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Stack>
  );
}

export default Veröffentlichungen;

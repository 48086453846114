import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { DotButton} from './EmblaCarouselArrowsDotsButtons'

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return (
    <IconButton {...other} disableRipple>
      <ExpandMoreIcon />
    </IconButton>
  );
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
  // Entfernen des Hover-Effekts
  '&:hover': {
    backgroundColor: 'transparent',
  },
}));

export default function Cards(props) {
  const { scrollSnaps, selectedIndex, scrollTo, ...otherProps } = props;

  const [isExpanded, setIsExpanded] = React.useState(false);
  const { title, image, content, paragraph1, paragraph2, paragraph3, imageP1, imageP2, imageP3, expanded, onExpandChange  } = props;

  const handleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Card sx={{ maxWidth: 1500, boxShadow: 'none'}} > 
     
      <CardMedia
        component="img"
        height="300"
        image={image}
        alt="timberuse"    
        onClick={onExpandChange}   
      />
       {/* Navigation mit Dots direkt unter CardMedia */}
       <div className="embla__dots">
        {scrollSnaps.map((_, index) => (
          <DotButton
          sx={{ marginTop: 20}}
            key={index}
            onClick={() => scrollTo(index)}
            className={'embla__dot'.concat(
              index === selectedIndex ? ' embla__dot--selected' : ''
            )}
          />
        ))}
      </div>
      <CardContent>
        <Typography sx={{ marginBottom: 0, marginTop: 0}}>
        <b>{content}</b>
        </Typography>
      </CardContent>
      <CardActions sx={{ marginTop: 0}}>
        <ExpandMore
          expand={expanded}
          onClick={onExpandChange}
          aria-expanded={expanded}
          aria-label="show more"
          sx={{          
            '& .MuiSvgIcon-root': {
              fontSize: '3rem',
            },                              
          }}
        >
          <ExpandMoreIcon/>              
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent >  
          {paragraph1 && <Typography paragraph><b>{paragraph1}</b></Typography>}
          {paragraph2 && <Typography paragraph>{paragraph2}</Typography>} 
          {paragraph3 && <Typography>{paragraph3}</Typography>}
          {imageP1 && <img src={imageP1} alt="groupImg" style={{width: '100%', height: 'auto', marginTop: '1rem', marginBottom: '1rem'}}/> }          
          {imageP2 && <img src={imageP2} alt="groupImg" style={{width: '100%', height: 'auto', marginTop: '1rem', marginBottom: '1rem'}}/>}        
          {imageP3 && <img src={imageP3} alt="groupImg" style={{width: '100%', height: 'auto', marginTop: '1rem', marginBottom: '1rem'}}/>}
          {/* {image && <img src={image} alt="groupImg" style={{width: '100%', height: 'auto', marginTop: '1rem', marginBottom: '1rem'}}/>} */}
        </CardContent>
      </Collapse>
    </Card>
  );
}
import React from "react";
import { Box, Link, Stack, Typography } from "@mui/material";
//import tumLogo from "../assets1/TUM_schwarz.png";
import tumLogo from "../assets1/TUM_weiss.png";

function Copyright(probs) {
  const { language } = probs;

  // Copyright Translation
  const copyrightDe = "2023 Technische Universität München";
  const copyrightEn = "2023 Technical University of Munich";
  const copyright = language === "de" ? copyrightDe : copyrightEn;

  // Translation Text
  const ImpressumDe = {
    contactID: "Impressum",
    Anschrift: "Anschrift",
    AnschriftText: "Technische Universität München",
    Umsatzsteueridentifikationsnummer: "Umsatzsteueridentifikationsnummer",
    UmsatzsteueridentifikationsnummerText: "DE811193231 (gemäß § 27a Umsatzsteuergesetz)",
    ZuständigeAufsichtsbehörde: "Zuständige Aufsichtsbehörde",
    ZuständigeAufsichtsbehördeText: "Bayerisches Staatsministerium für Wissenschaft und Kunst",
    Vertretungsberechtigt: "Rechtsform und Vertretung",
    VertretungsberechtigtText: "Die Technische Universität München ist eine Körperschaft des Öffentlichen Rechts und staatliche Einrichtung (Art. 11 Abs. 1 BayHSchG). Sie wird gesetzlich vertreten durch den Präsidenten Prof. Dr. Thomas F. Hofmann.",
    InhaltlichVerantwortlich: "Inhaltlich Verantwortlich",
  };
  const ImpressumEn = {
    contactID: "Imprint",
    Anschrift: "Publisher",
    AnschriftText: "Technical University of Munich",
    Umsatzsteueridentifikationsnummer: "VAT identification number",
    UmsatzsteueridentifikationsnummerText: "DE811193231 (in accordance with § 27a of the German VAT tax act - UStG)",
    ZuständigeAufsichtsbehörde: "Supervisory authority",
    ZuständigeAufsichtsbehördeText: "Bavarian State Ministry of Science and the Arts",
    Vertretungsberechtigt: "Legal status and representation",
    VertretungsberechtigtText: "The Technical University of Munich is a corporation under public law and a state institution (Art. 11(1) of the Bavarian Higher Education Act - BayHSchG). Its legal representative is Prof. Dr. Thomas F. Hofmann.",
    InhaltlichVerantwortlich: "Responsible for content",

  };
  const Impressum = language === "de" ? ImpressumDe : ImpressumEn;

  return (
    <Stack>


      <Stack
        //px={{ xs: "2%", sm: "7%" }}
        direction={{ xs: "column", sm: "column", md: "row" }}
        //py={{ xs: "25px", sm: "40px", md: "50px" }}
        px={{ xs: "2%", sm: "7%" }}
        display='flex'
        className="copyright-titel no-border"
      >

        <Typography
          id={Impressum.contactID}
          variant="h2"
          fontWeight="800"
          fontSize={{ xs: "28px", sm: "34px", md: "44px" }}
          mb={{ xs: "15px", sm: "25px" }}
        >
          <br />
          {Impressum.contactID}
        </Typography>
      </Stack>
      <Typography lineHeight={0} paddingTop="35px" paddingLeft={{ xs: "0%", sm: "4%", md: "7%" }} sx={{ backgroundColor: '#0B0E1E' }}>
        <Box><img height="40px" src={tumLogo} alt="graphic" /></Box>
      </Typography>
      <Stack
        direction={{ xs: "column", sm: "column", md: "row" }}
        px={{ xs: "2%", sm: "7%" }}
        className="copyright no-border"
        py="1%"
      >
        <Box flex="0.5" paddingRight={{ xs: "0%", sm: "3%", md: "6%" }} sx={{ order: 1 }} textAlign="left">
          <Typography variant="caption" paddingTop={{ xs: "0%", sm: "3%", md: "30%" }}>
            <br /><b>{Impressum.Anschrift}</b>
            <br />{Impressum.AnschriftText}
            <br />Arcisstraße 21
            <br />80333 München
            <br />+4989289 01
            <br />
            <br /><b>{Impressum.Umsatzsteueridentifikationsnummer}</b>
            <br />{Impressum.UmsatzsteueridentifikationsnummerText}
            <br />
            <br /><b>{Impressum.ZuständigeAufsichtsbehörde}</b>
            <br />{Impressum.ZuständigeAufsichtsbehördeText}
          </Typography>
          <Typography variant="caption">
          <br />
          <br /><b>desined by</b>
          <br />Jakob Fellner        
          </Typography>
        </Box>
        
        <Box
          flex="0.5"
          sx={{ order: 2 }}
          textAlign="left"
        >
          <Typography variant="caption">
            <br /><b>{Impressum.Vertretungsberechtigt}</b>
            <br />{Impressum.VertretungsberechtigtText}
            <br />
            <br /><b>{Impressum.InhaltlichVerantwortlich}</b>
            <br />Dr. Michael Risse (TUM.wood)
            <br />Winzererstr. 45
            <br />80797 München
            <br /><Link
            className="footer-link"
            href="mailto:risse@hfm.tum.de"
            color={"#ffffff"}>
            richter@hfm.tum.de
            </Link>
            <br /><Link 
            href="https://www.holz.tum.de/" 
            target="_blank" 
            rel="noopener" 
            underline="hover" 
            color={"#ffffff"}>
            https://www.holz.tum.de/
            </Link>
          </Typography>
          
        </Box>
        
        

      </Stack>
      <Stack
        className="copyright-header no-border"
        py="3%"
      >
        <b>{copyright}</b>
      </Stack>

    </Stack>
  );
}

export default Copyright;

import React from "react";
import { Box, Typography, Stack, MenuItem } from "@mui/material";
import group4 from "../assets1/CfH2_Logo_RGB.svg";
import bmelDe from "../assets1/BMEL_de.svg";
import bmelEn from "../assets1/BMEL_en.gif";

function Förderung(probs) {
  const { language } = probs;

  const bmel = language === "de" ? bmelDe : bmelEn;
  const ArrayDe = [
    {
      link: "https://www.bmel.de/",
      image: bmel
    },
    {
      link: "https://www.charta-fuer-holz.de/",
      image: group4
    },
   
  ];

  const ArrayEn = [
    {
      link: "https://www.bmel.de/EN/Home/home_node.html",
      image: bmel
    },
    {
      link: "https://www.charta-fuer-holz.de/",
      image: group4
    },
  
  ];
  const Array = language === "de" ? ArrayDe : ArrayEn;

  // Titel und Text
  const FundingDe = {
    title: "Förderung",
    text1: "Bundesministerium für Ernährung und Landwirtschaft (BMEL)",
    text2: "unter Projektträgerschaft der Fachagentur Nachwachsende Rohstoffe e. V. (FNR).",
    //text: "Yet bed any for travelling assistance indulgence unpleasing. Not thoughts all exercise blessing. Indulgence way everything joy alteration boisterous the attachment.",
  };
  const FundingEn = {
    title: "Funding",
    text1: "Federal Ministry of Food and Agriculture (BMEL)",	
    text2: "under the project sponsorship of the the Agency for Renewable Resources (FNR).",
    //text: "Das Gesamtziel des Projekts ist daher die Entwicklung des Holzrahmenbaus unter der Prämisse der gestalterischen, technischen, wirtschaftlichen und ökologischen Machbarkeit.",
  };
  const Funding = language === "de" ? FundingDe : FundingEn;

  return (
    <Box px={{ xs: "2%", sm: "7%" }} pt="35px" pb="50px">
      <Typography
        variant="h2"
        fontWeight="800"
        fontSize={{ xs: "28px", sm: "34px", md: "44px" }}
        mt={{ xs: "10px", sm: "15px", md: "20px" }}
        textAlign="left"
        mb={{ xs: "15px", sm: "25px" }}
      >
        <br />{Funding.title}
      </Typography>
      <Typography
        variant="body1"
        fontWeight="500"
      >
        {Funding.text1}
        <br/>{Funding.text2}
      </Typography>
      
{/*
      <Typography variant="h6" fontWeight="600" textAlign="center" py="25px" px="7%">
        {Funding.title}
      </Typography>
*/ }
      <Stack
        direction={{ xs: "column", sm: "column", md: "row" }}
        spacing={{ xs: 6, sm: 6 }}
        alignItems="center"
        justifyContent="center"
      >
        {Array.map((detail) => (
          <MenuItem
            sx={[
              { gap: 5 },
              {
                '&:hover': {
                  backgroundColor: 'white',
                },
              },
            ]}
            component="a"
            href={detail.link}
            target="_blank"
          >
            <img
              className="client-business-logo"
              src={detail.image}
              alt="client-business-logos"
            />
          </MenuItem>
        ))}
      </Stack>
    </Box>
  );
}

export default Förderung;

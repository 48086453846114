// @ts-nocheck
import React, { useState } from "react";
import { Link } from "react-scroll";
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Container,
  MenuItem,
  IconButton,
  Menu,
  useMediaQuery
} from "@mui/material";
import Switch, { } from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import { styled, useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import tumLogoDe from "../assets1/TU&M_Logo_en_RGB.png";
import tumLogoEn from "../assets1/TU&M_Logo_en_RGB.png";

// const pages = ["Forschungsvorhaben", "Konsortium", "Forschung", "Arbeitspakete", "Veröffentlichungen", "Impressum"];
// Appbar component from mui..................................
// position="fixed"  --> um Navbar zu fixieren!
//Make our variable for spacing. The default value is 0.
//When executed, change the value of the spacing Hook to the value specified.

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  //display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,

    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',

      '& + .MuiSwitch-track': {
        opacity: 1,
        //backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
        backgroundColor: theme.palette.mode === 'dark' ? '#0B0E1E' : '#0B0E1E',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(11 11 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

function Navbar(probs) {
  const { language, handleLanguageChange } = probs;

  const pagesDe = ["Forschungsvorhaben", "Aktuelles", "Arbeitspakete", "Veröffentlichungen", "Konsortium", "Kontakt", "Impressum"];
  const pagesEn = ["Research Project", "News", "Work Packages", "Publications", "Consortium", "Contact", "Imprint"];
  const pages = language === "de" ? pagesDe : pagesEn;

  const tumLogo = language === "de" ? tumLogoDe : tumLogoEn;

  // Theme for breakpoint Menu
  const theme = useTheme();

  // Mobile Menu
  const [anchorEl, setAnchorEl] = useState(null);
  const [darkMode, setDarkMode] = useState(false);
  //const isXs = useMediaQuery((theme) => theme.breakpoints.down("xs")); // Überprüfen Sie, ob der Breakpoint "xs" ist

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDarkModeChange = (event) => {
    setDarkMode(event.target.checked);
  };

  function renderMenuItems(pages, handleClose) {
    return pages.map((page) => (
      <Link activeClass="active" to={page} spy={true} smooth={true}>
        <MenuItem key={page.id} onClick={handleClose} sx={{ width: "100vw", paddingTop: "5px" }} >
          <Typography>{page}</Typography>
        </MenuItem>
      </Link>
    ));
  }

  const isXs = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <AppBar
      //display="flex" 
      position="sticky"
      className="appbar"
      variant="dense"
    //justifyContent="flex-start"
    >
      

      <Toolbar
        disableGutters
        variant="dense"
        

      //justifyContent="left" 
      //</Container>display="flex"
    
      >
        <Typography variant="h6" component="div" lineHeight={0} sx={{ paddingTop: "5px" }} px={{ xs: "0%", sm: "1.15%" }}>
          <Box><img className="TUM-image" height="60px" src={tumLogo} alt="graphic" /></Box>
        </Typography>
        <div>
          {/* IconButton wird nur beim Breakpoint "xs" angezeigt */}
          {isXs && (
            <IconButton
              aria-controls="mobile-menu"
              aria-haspopup="true"
              onClick={handleClick}
            //className="menu-icon-button"
            //sx={{ marginTop: "3px" }}
            >
              <MenuIcon />
            </IconButton>
          )}
        </div>

        <Menu
          id="mobile-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          classes={{ paper: "menu-paper" }}

        >
          {renderMenuItems(pages, handleClose)}
        </Menu>

        {/* IconButton wird bei Breakpoints größer als "xs" angezeigt */}
        {!isXs && (
          <Box
          px={{ xs: "0%", sm: "5%" }}
            sx={{
              gap: { xs: "0%", sm: "0%", md: "0%", lg: "2%", xl: "3%" },
              flexGrow: 1,
              //display: { xs: "none", md: "flex", gap: "1%" },
              flexWrap: 'wrap',
              display: "flex",
              //gap: "5%",
              justifyContent: "flex-start",

              //marginTop: "50px",
            }}
          >
            {pages.map((page) => (
              <Link activeClass="active" to={page} spy={true} smooth={true}>
                <MenuItem>
                  <Typography>{page}</Typography>
                </MenuItem>
              </Link>
            ))}
          </Box>
        )}
       
          <Stack
            //justifyContent={isXs ? 'flex-end' : 'flex-end'}
            //px={{ xs: "15%", sm: "5%" }}
            direction={"row"}
            alignItems={isXs ? 'center' : 'center'}
            //textAlign={"flex-start"}
            spacing={1}
            display={"flex"}
            //justifyContent={"right"}
            paddingLeft={isXs ? '50%' : '0%'}
          >
            <Typography>de</Typography>
            <AntSwitch
              checked={language === "en"}
              onChange={handleLanguageChange}
              value="en"
              inputProps={{ "aria-label": "ant design" }}
            />
            <Typography>en</Typography>
          </Stack>
           
      </Toolbar>

    </AppBar>
  );
}

// TUM LOGO
// // <img className="nav-tumlogo" src={tumlogo} alt="graphic" />

export default Navbar;



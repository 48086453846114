import React from "react";
import { Box, Typography, Stack, } from "@mui/material";
import group1 from "../assets1/Group1.png";
import group2 from "../assets1/Group2.png";
import tumLogo from "../assets1/TUM_schwarz.png";
import { styled } from '@mui/material/styles';
import MuiGrid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';

const Grid = styled(MuiGrid)(({ theme }) => ({
  width: '100%',
}));


const PartnerDetails = [
  {
    title: "Haas Fertigbau GmbH",
    title2: "•",
    description:
      "PL Xaver Haas | PB Martin Stummer",
    extra: "Praxis",
    picture: group1,
    pic_height: "30px",
    link: "https://haas-fertigbau.de/"
  },
  {
    title: "Brüninghoff Holz GmbH & Co. KG",
    title2: "•",
    description:
      "PL Dario Kemper | PB Frederic Schenke",
    extra: "Praxis",
    picture: group2,
    pic_height: "30px",
    link: "https://www.brueninghoff.de/"
  },
];


function Partner(probs) {
  const { language } = probs;

  // Translation Cards
  const FörderungDetailsDe = [
    {
      title: "Holzwissenschaft",
      title2: "•",
      description: "Prof. Klaus Richter | Florian Böhm",
      extra: "Forschung AP1 AP4",
      picture: tumLogo,
      pic_height: "30px",
      link: "https://www.ls.tum.de/hfm/lehrstuhl-fuer-holzwissenschaft/"
    },
    {
      title: "Architektur und Holzbau",
      title2: "•",
      description: "Prof. Stephan Birk | Tabea Huth",
      extra: "Forschung AP2",
      picture: tumLogo,
      pic_height: "30px",
      link: "https://www.arc.ed.tum.de/holz/aktuell/"
    },
    {
      title: "Holzbau und Baukonstruktion",
      title2: "•",
      description: "Prof. Stefan Winter | Zsofia Varga",
      extra: "Forschung AP3",
      picture: tumLogo,
      pic_height: "30px",
      link: "https://www.cee.ed.tum.de/hbb/startseite/"
    },
    {
      title: "Architekturinformatik",
      title2: "•",
      description: "Prof. Frank Petzold | Jakob Fellner",
      extra: "Forschung AP5",
      picture: tumLogo,
      pic_height: "30px",
      link: "https://www.arc.ed.tum.de/ai/aktuelles/"
    },
    {
      title: "Circular Economy",
      title2: "•",
      description: "Prof. Magnus Fröhling | Josef Huber",
      extra: "Forschung AP6",
      picture: tumLogo,
      pic_height: "30px",
      link: "https://cec.cs.tum.de/"
    },
  ];
  const FörderungDetailsEn = [
    {
      title: "Wood Science",
      title2: "•",
      description: "Prof. Klaus Richter | Florian Böhm",
      extra: "Research WP1 WP4",
      picture: tumLogo,
      pic_height: "30px",
      link: "https://www.ls.tum.de/en/hfm/chair-of-wood-science/"
    },
    {
      title: "Architecture and",
      title2: "Timber Construction",
      description: "Prof. Stephan Birk | Tabea Huth",
      extra: "Research WP2",
      picture: tumLogo,
      pic_height: "30px",
      link: "https://www.arc.ed.tum.de/en/holz/news/"
    },
    {
      title: "Timber Structures and",
      title2: "Building Construction",
      description: "Prof. Stefan Winter | Zsofia Varga",
      extra: "Research WP3",
      picture: tumLogo,
      pic_height: "30px",
      link: "https://www.cee.ed.tum.de/en/hbb/home/"
    },
    {
      title: "Architectural Informatics",
      title2: "•",
      description: "Prof. Frank Petzold | Jakob Fellner",
      extra: "Research WP5",
      picture: tumLogo,
      pic_height: "30px",
      link: "https://www.arc.ed.tum.de/en/ai/current-affairs/"
    },
    {
      title: "Circular Economy",
      title2: "•",
      description: "Prof. Magnus Fröhling | Josef Huber",
      extra: "Research WP6",
      picture: tumLogo,
      pic_height: "30px",
      link: "https://cec.cs.tum.de/?lang=en"
    },
  ];
  const FörderungDetails = language === "de" ? FörderungDetailsDe : FörderungDetailsEn;

  // Titel und Text
  const ConsortiumDe = {
    title: "Konsortium",
    //text: "Yet bed any for travelling assistance indulgence unpleasing. Not thoughts all exercise blessing. Indulgence way everything joy alteration boisterous the attachment.",
  };
  const ConsortiumEn = {
    title: "Consortium",
    //text: "Yet bed any for travelling assistance indulgence unpleasing. Not thoughts all exercise blessing. Indulgence way everything joy alteration boisterous the attachment.",
  };
  const Consortium = language === "de" ? ConsortiumDe : ConsortiumEn;

  // Titel und Text
  const InfoDe = {
    chair: "Lehrstuhl für",
    company: "  ",
    practice: "Praxis",
    research: "Forschung",
  };
  const InfoEn = {
    chair: "Chair of",
    company: "  ",
    practice: "Practice",
    research: "Research",
  };
  const Info = language === "de" ? InfoDe : InfoEn;

  return (
    // px Solution very dirty dirty
    <Box
      textAlign="center"
      pt="30px"
      pb="10px"
    //px={{ xs: 7, sm: 25 }} 
    //id={Consortium.title}
    ><div id={Consortium.title}> </div><br /><br />
      <Typography
        variant="h2"
        fontWeight="800"
        fontSize={{ xs: "28px", sm: "34px", md: "44px" }}
        mt={{ xs: "10px", sm: "15px", md: "20px" }}
        textAlign="left"
        px={{ xs: "2%", sm: "7%" }}
      >
        {Consortium.title}
      </Typography>
      {/** Forschungspartner Stack */}
      <Stack
        py="50px"
        flexDirection={{ xs: "column", sm: "column", md: "row", xl: "row" }}
        //spacing={{ xs: 2, sm: 2, md: 4 }}
        gap={6}
        rowGap={6}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap={"wrap"}
      >
        {FörderungDetails.map((detail) => (
          <Stack
            className="Konsortium-info-card"
            borderRadius="10px"
            //px={{ xs: "10px", sm: "10px", md: "15px" }}
            //py={{ xs: "15px", sm: "25px", md: "15px" }}
            //sx={{ flexWrap: 'wrap' }}
            maxWidth="290px"
            minWidth="290px"
            minHeight="190px"
            component="a"
            href={detail.link}
            target="_blank"
            color="#000000"
            style={{ textDecoration: 'none' }}
          >
            <Box sx={{ mx: 2, mt: 2 }}>
              <Grid container alignItems="center">
                <Grid item xs>
                  <Typography gutterBottom variant="h6" component="div" lineHeight={0}>
                    <Box><img className="TUM-image" height={detail.pic_height} src={detail.picture} alt="graphic" /></Box>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography gutterBottom variant="subtitle1" component="div" fontWeight="600" lineHeight={1.2} fontSize="14px">
                    {Info.chair}<br />{detail.title}<br />{detail.title2}
                  </Typography>
                </Grid>
              </Grid>
              {/**  
              <Grid item>
                <Typography color="text.secondary" variant="body2" fontSize="12px">
                  {detail.description}
                </Typography>
              </Grid>
              */}
            </Box>
            <Divider variant="middle" sx={{ my: "7px" }} />
            <Box sx={{ m: 1 }} lineHeight={0.1} >
              <Typography gutterBottom variant="subtitle2" lineHeight={0.5} fontSize="12px">
                {detail.extra}
              </Typography>
            </Box>
          </Stack>
        ))}
      </Stack>

      {/** Praxispartner Stack */}
      <Stack
        py={4}
        flexDirection={{ xs: "column", sm: "column", md: "row", xl: "row" }}
        //spacing={{ xs: 2, sm: 2, md: 4 }}
        //spacing={4}
        gap={6}
        rowGap={6}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap={"wrap"}
      >
        {PartnerDetails.map((detail) => (
          <Stack
            className="Konsortium-info-card"
            borderRadius="10px"
            //px={{ xs: "10px", sm: "10px", md: "15px" }}
            //py={{ xs: "15px", sm: "25px", md: "15px" }}
            //sx={{ flexWrap: 'wrap' }}
            maxWidth="290px"
            minWidth="290px"
            minHeight="190px"
            component="a"
            href={detail.link}
            target="_blank"
            color="#000000"
            style={{ textDecoration: 'none' }}
            justifyContent="center"
          >
            <Box sx={{ mx: 2, mt: 2 }}>
              <Grid container>
                <Grid item xs>
                  <Typography gutterBottom variant="h6" component="div" lineHeight={0}>
                    <Box><img className="TUM-image" height="30px" src={detail.picture} alt="graphic" /></Box>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography gutterBottom variant="subtitle1" component="div" fontWeight="600" lineHeight={1.2} fontSize="14px">
                    {Info.company}<br />{detail.title}<br />{detail.title2}
                  </Typography>
                </Grid>

              </Grid>
              {/** 
              <Typography color="text.secondary" variant="body2" fontSize="12px">
                {detail.description}
              </Typography>
              */}
            </Box>
            <Divider variant="middle" sx={{ my: "7px" }} />
            <Box sx={{ m: 1 }} lineHeight={0.1}>
              <Typography gutterBottom variant="subtitle2" lineHeight={0.5} fontSize="12px">
                {Info.practice}
              </Typography>
            </Box>
          </Stack>
        ))}
      </Stack>

    </Box>
  );
}
export default Partner;

import React from "react";
import { Box, Typography, Button } from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import tumLogo from "../assets1/TUM_schwarz.png";

function Contact(probs) {
  const { language } = probs;

  // Translation Text
  const ImprintDe = {
    contactID: "Impressum",
    contactQuestion: "Fragen zum Projekt?",
    contactButton: "NIMM KONTAKT ZU UNS AUF",
  };
  const ImprintEn = {
    contactID: "Imprint",
    contactQuestion: "Any questions?",
    contactButton: "CONTACT US",
  };
  const Imprint = language === "de" ? ImprintDe : ImprintEn;

   // Translation Text
   const ContactDe = {
    contactID: "Kontakt",
  };
  const ContactEn = {
    contactID: "Contact",
  };
  const Contact = language === "de" ? ContactDe : ContactEn;

  return (
    <Box
      className="join-waitlist"
      display="flex"
      flexDirection="column"
      p="5%"
      textAlign="center"
      alignItems="center"
      justifyContent="center"
      pb={{ xs: "10%", sm: "4%" }}
      id={Contact.contactID}
    >            
      <Typography
        id={Imprint.contactID}
        variant="h3"
        color="#ffffff"
        fontWeight="400"
        fontSize={{ xs: "25px", sm: "32px", md: "40px" }}
        width={{ xs: "100%", sm: "70%", md: "50%" }}
        mb="20px"
      >
        {Imprint.contactQuestion}
      </Typography>
      <Button
        href="mailto:florian.boehm@tum.de"
        sx={{ mt: "20px" }}
        variant="contained"
        className="waitlist-button"
        endIcon={<ArrowRightAltIcon />}
      >
        {Imprint.contactButton}
      </Button>
    </Box>
  );
}

export default Contact;
